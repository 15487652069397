<template>
  <div class="main-layout__content">
    <div class="content">
      <h1 class="page-title">Purchase Subscription</h1>
      <div class="container text-center">
        <template v-if="tier">
          <template v-if="loggedIn">
            <div class="checkout-form">
              <h2>{{ $t(`subscriptions.tier${tier}`) }}</h2>
              <div>
                <span class="cost">${{ basePrice }}</span>
                <span class="duration text-muted">/Mo</span>
              </div>
              <div>
                <img :src="ticketImage" alt="Ticket" />
              </div>
              <b-form-select
                v-model="months"
                :options="options"
                class="purchase-options"
              ></b-form-select>
              <b-card v-if="months !== null" class="stripe-card mt-3">
                <b-card-text>
                  {{ $t(`subscriptions.tier${tier}`) }}</b-card-text
                >
                <b-card-text>Price: ${{ price }}</b-card-text>
                <!-- <StripePurchase
                  :item="item"
                  :key="item.item_id"
                  v-on:purchaseSuccess="onPurchaseSuccess"
                  v-on:error="onError"
                /> -->
                <!-- <PaypalSubscription :tier="tier" /> -->
                <b-alert v-model="showError" variant="danger" dismissible>{{
                  error
                }}</b-alert>
              </b-card>
              <PaypalPurchase
                class="mt-3"
                :item="item"
                :key="item.item_id"
                :paypalType="paypalType"
                v-on:purchaseSuccess="onPurchaseSuccess"
              />
            </div>
          </template>
          <template v-else>
            <LoginButton />
          </template>
        </template>
        <template v-else>No tier {{ tier }}</template>
      </div>
    </div>
  </div>
</template>

<script>
import LoginButton from "../login/LoginButton";
// import PaypalSubscription from "../payment/PaypalSubscription";
// import StripeSubscription from "../payment/StripeSubscription";
// import StripePurchase from "../payment/StripePurchase";
import PaypalPurchase from "../payment/PaypalPurchase";

export default {
  data() {
    return {
      error: "",
      showError: false,
      loading: true,
      months: null,
      options: [
        { value: null, text: "Months" },
        { value: 1, text: "1 Month" },
        { value: 6, text: "6 Months (Save 10%)" },
        { value: 12, text: "12 Months (Save 20%)" },
      ],
    };
  },

  components: {
    LoginButton,
    // PaypalSubscription,
    // StripeSubscription,
    // StripePurchase,
    PaypalPurchase,
  },

  created() {
    if (!this.tier) {
      this.$router.push("/subscriptions");
    }
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    tier() {
      const route = this.$route.params.tier;
      const tier = route.substring(4);
      if (tier == 1 || tier == 2 || tier == 3) {
        return Number(tier);
      }
      return null;
    },
    item() {
      return {
        item_id: `bp_${this.tier}_${this.months}`,
        cost_usd: this.price,
      };
    },
    basePrice() {
      switch (this.tier) {
        case 1:
          return 2;
        case 2:
          return 5;
        case 3:
          return 15;
        default:
          return 0;
      }
    },
    discount() {
      switch (this.months) {
        case 1:
          return 0;
        case 6:
          return 10;
        case 12:
          return 20;
        default:
          return 0;
      }
    },
    price() {
      const price = this.basePrice * this.months;
      const discount = this.discount;
      return (price - (price * discount) / 100).toFixed(2);
    },
    paypalType() {
      return this.price < 12 ? "cheap" : "expensive";
    },
    ticketImage() {
      const tier = this.tier;
      return require(`./images/ticketicon_${tier}.png`);
    },
  },

  methods: {
    onPurchaseSuccess() {
      this.$router.push("/payment_success");
    },
    onError(error) {
      this.error = error;
      this.showError = true;
    },
  },
};
</script>

<style scoped>
.card-body {
  color: black;
}

.purchase-options {
  max-width: 200px;
  margin: auto;
}

.cost {
  font-size: 24px;
}

.checkout-form {
  max-width: 600px;
  margin: auto;
}

.stripe-card {
  width: 500px;
  margin: auto;
}
</style>